import { useEffect } from 'react';
import Close from '../assets/images/close.png'
import acervoPopup from '../assets/images/popup_acervo.png'
import gsap from 'gsap';

export default function Popup() {
  const scrollToContact = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  const handeClick = () => {
    scrollToContact();
    closePopUp();
  }

  function closePopUp() {
    document.querySelector("#popup").setAttribute("data-active", 'none')
  }


  useEffect(() => {
    gsap.fromTo('#popup', {
      opacity: 0
    }, {
      opacity: 1,
      delay: 1
    })
  })

  return (
    <>
      <div id='popup' data-active="active" className="bg-black/30 fixed top-0 left-0 w-full h-full z-50 hidden place-items-center  data-[active=active]:grid data-[active=none]:hidden " onClick={closePopUp}>
        <div className="flex flex-col justify-center items-center ">
          <div className='bg-white w-[95%] sm:w-1/2 sm:aspect-square flex p-0 relative gap-3'>
            <img src='PopUp18092024.webp' alt='CONHEÇA OS ÚLTIMOS ITENS
DISPONÍVEIS DO ACERVO.
Para morar com a conveniência de fazer tudo a pé:
Flora, Parque Sustentável da Gávea e Marias.
Para investir: Largo do Piva.' />
             <img id='close-popup' className="w-[12px] aspect-square object-contain absolute invert right-0 md:-right-4 -top-4 z-50 cursor-pointer" src={Close} onClick={closePopUp} alt="Fechar" />
            {/*<div className='flex flex-col w-[55%] gap-1'>
              <h1 className='text-[#5b6551] text-xl gap-1 font-semibold mb-4 relative
                before:absolute before:w-[15%] before:top-[-5px] before:h-[2px] before:bg-[#5b6551]
              '>TER UM MOZAK É<br /> TER O RIO PARA VOCÊ</h1>
              <img src='./popup/img-popup1.jpg' alt='acervo' className='flex-grow h-[10px] object-cover' />
              <img src='./popup/img-popup2.jpg' alt='acervo' className='flex-grow h-[10px] object-cover' />
              <img src='./popup/img-popup3.jpg' alt='acervo' className='flex-grow h-[10px] object-cover object-bottom' />
            </div>
            <div className='flex flex-col w-[45%]'>
              <img src='./popup/img-popup4.jpg' alt='acervo' className='w-full h-[55%] object-cover' />
              <div className='flex-grow flex flex-col gap-4 sm:justify-around'>
                <h2 className='font-semibold mt-3'>OS ÚLTIMOS IMÓVEIS DO ACERVO ESTÃO À SUA ESPERA</h2>
                <p className='text-sm'>Para morar com a conveniência de fazer tudo à pé: Flora, Parque Sustentável da Gávea e Marias</p>
                <p className='text-sm'>Para investir com retorno garantido: Largo do Piva</p>
                <button className='text-[#5b6551] font-medium text-start text-base border-b-2 w-fit border-[#5b6551] hover:text-white hover:bg-[#5b6551] transition-all px-2' onClick={handeClick}>CONHEÇA NOSSO ACERVO</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}